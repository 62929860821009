<template>
  <div id="article">
    <div class="acticle-content">
      <h1>开发中...</h1>
    </div>
  </div>
</template>

<script setup>
</script>
<style scoped>
#article {
  height: 100vh;
  color: #fff;
  position: relative;
}

.acticle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
